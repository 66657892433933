import { FC, useEffect, useState } from 'react';
import { UseMutateFunction } from '@tanstack/react-query';
import ChatInput from './input';
import ChatMessages from './messages';
import ChatSuggestions from './suggestions';
import { ChatResponse, Message, Mode } from './types';
import ChatInfo from './info';
import { DEMO_MESSAGES } from './constants';

interface ChatInterfaceProps {
    mode: Mode;
    brand: string;
    title?: string;
    suggestionCaretColor?: string;
    suggestions?: string[];
    sendChat: UseMutateFunction<
        void,
        Error,
        {
            message: any;
            setResponse: any;
            setInProgress: any;
        },
        unknown
    >;
    chatLoading: boolean;
}

const ChatInterface: FC<ChatInterfaceProps> = ({
    mode,
    suggestions,
    brand,
    chatLoading,
    sendChat,
    suggestionCaretColor,
    title,
}) => {
    const [input, setInput] = useState('');

    const [response, setResponse] = useState<ChatResponse[] | ChatResponse>();
    const [inProgress, setInProgress] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);

    const sendMessage = () => {
        if (input.trim() === '') return;
        const newMessage: Message = {
            id: Date.now(),
            role: 'User',
            text_input: [input],
            message_log: [],
        };
        sendChat({ message: newMessage, setResponse, setInProgress });
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInput('');
    };

    useEffect(() => {
        if (!response) return;

        const textInputs: string[] = [];
        let error = false;
        if (Array.isArray(response)) {
            response.forEach(textInput => {
                if (textInput?.error) {
                    error = true;
                    return;
                }
                if (textInput?.value) {
                    textInputs.push(textInput?.value);
                }
            });
        } else {
            textInputs.push(response?.content);
        }

        if (error && textInputs.length === 0) {
            textInputs.push('Sorry, I did not understand that.');
        }

        const newMessage: Message = {
            id: Date.now(),
            role: 'AI',
            text_input:
                brand === 'nestle'
                    ? [mode !== 'full-page' ? DEMO_MESSAGES[0] : DEMO_MESSAGES[1]]
                    : textInputs,
            message_log: [],
        };

        setMessages(prevMessages => [...prevMessages, newMessage]);
        setResponse(undefined);
    }, [response, brand]);

    return (
        <div className="flex flex-col px-14 h-full w-full justify-between">
            <div className="flex max-h-[80%] flex-col">
                {mode === 'full-page' && <ChatInfo mode={mode} />}
                <ChatMessages
                    mode={mode}
                    messages={[messages]}
                    loading={chatLoading}
                    title={title}
                />
            </div>
            <div className=" bottom-2 flex-col">
                {messages.length === 0 && (
                    <ChatSuggestions
                        suggestions={suggestions}
                        onSelect={suggestion => {
                            setInput(suggestion);
                        }}
                        suggestionCaretColor={suggestionCaretColor}
                    />
                )}
                <ChatInput
                    input={input}
                    setInput={setInput}
                    sendMessage={sendMessage}
                    disabled={inProgress}
                    title={title}
                />
            </div>
        </div>
    );
};
export default ChatInterface;
